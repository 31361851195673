import { useState, useEffect } from 'react';

interface InAppBrowserState {
  isInAppBrowser: boolean;
  isIOS: boolean;
}

export function useInAppBrowserCheck(): InAppBrowserState {
  const [state, setState] = useState<InAppBrowserState>({
    isInAppBrowser: false,
    isIOS: false
  });

  useEffect(() => {
    // Only run on client-side
    if (typeof window === 'undefined') return;

    const userAgent = window.navigator.userAgent.toLowerCase();

    // Define patterns to match (all lowercase)
    const iosPatterns = ['iphone', 'ipad'];
    const inAppBrowserPatterns = [
      'linkedinapp', // LinkedIn app browser
      'fban', // Facebook app browser
      'fbios', // Facebook iOS app
      'instagram', // Instagram app browser
      'ig-facebook' // Alternative Instagram browser identifier
    ];

    // Check if user agent matches any iOS pattern
    const isIOS = iosPatterns.some(pattern => userAgent.includes(pattern));

    // Check if user agent matches any in-app browser pattern
    const isInAppBrowser = inAppBrowserPatterns.some(pattern => userAgent.includes(pattern));

    setState({
      isInAppBrowser,
      isIOS
    });
  }, []);

  return state;
}
