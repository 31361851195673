import React from 'react';

interface PicStudioGlyphProps {
  className?: string;
  gradientColors?: {
    start: string;
    end: string;
  };
}

export const PicStudioGlyph: React.FC<PicStudioGlyphProps> = ({
  className,
  gradientColors = { start: '#3B82F6', end: '#EF4444' }
}) => {
  const gradientId = 'picStudioGradient';
  const maskId = 'picStudioMask';

  return (
    <svg width="128" height="128" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={gradientColors.start} />
          <stop offset="100%" stopColor={gradientColors.end} />
        </linearGradient>
        <mask id={maskId}>
          <path fill="white" d="M145.507 128.358H192.03V151.62H145.507V128.358Z" />
          <path
            fill="white"
            d="M306.387 124.307C314.834 130.39 323.875 147.6 324.218 181.823C324.544 214.461 316.459 255.358 298.855 295.818C281.252 336.278 258.382 366.528 236.586 383.234C213.733 400.752 197.603 399.834 189.156 393.75C184.506 390.401 179.675 383.681 176.216 372.607H154.816C159.52 391.577 167.88 406.442 179.875 415.08C218.513 442.907 280.233 395.598 317.731 309.412C355.229 223.227 354.305 130.803 315.667 102.976C284.504 80.5333 238.326 106.966 201.952 163.251H219.943V178.786C232.422 159.738 245.882 144.844 258.956 134.822C281.81 117.305 297.939 118.223 306.387 124.307Z"
          />
          <path
            fill="white"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M217.036 297.006C239.518 297.006 257.744 278.78 257.744 256.298C257.744 233.815 239.518 215.59 217.036 215.59C194.553 215.59 176.328 233.815 176.328 256.298C176.328 278.78 194.553 297.006 217.036 297.006ZM217.036 276.652C228.277 276.652 237.39 267.539 237.39 256.298C237.39 245.057 228.277 235.944 217.036 235.944C205.794 235.944 196.682 245.057 196.682 256.298C196.682 267.539 205.794 276.652 217.036 276.652Z"
          />
          <path
            fill="white"
            d="M124.78 175.952C122.245 180.928 122.245 187.442 122.245 200.47V218.498H174.005C184.671 206.039 200.513 198.143 218.2 198.143C235.886 198.143 251.729 206.039 262.395 218.498H343.188C345.692 198.621 345.935 179.865 343.928 163.251H159.464C146.436 163.251 139.922 163.251 134.946 165.786C130.569 168.016 127.011 171.575 124.78 175.952Z"
          />
          <path
            fill="white"
            d="M364.838 163.39C365.751 171.211 366.213 179.11 366.291 186.92C366.393 197.117 365.871 207.685 364.739 218.498H389.755V200.47C389.755 187.442 389.755 180.928 387.22 175.952C384.99 171.575 381.431 168.016 377.054 165.786C373.969 164.214 370.292 163.617 364.838 163.39Z"
          />
          <path
            fill="white"
            d="M339.318 241.759H274.522C275.718 246.406 276.354 251.278 276.354 256.298C276.354 288.416 250.318 314.452 218.2 314.452C186.082 314.452 160.045 288.416 160.045 256.298C160.045 251.278 160.682 246.406 161.878 241.759H122.245V312.126C122.245 325.154 122.245 331.668 124.78 336.644C127.011 341.021 130.569 344.579 134.946 346.81C139.922 349.345 146.436 349.345 159.464 349.345H297.115C304.52 337.275 311.468 323.949 317.74 309.535C327.568 286.947 334.756 263.93 339.318 241.759Z"
          />
          <path
            fill="white"
            d="M323.937 349.345C328.407 341.004 332.647 332.25 336.616 323.129C348.279 296.323 356.611 268.594 361.424 241.759H389.755V312.126C389.755 325.154 389.755 331.668 387.22 336.644C384.99 341.021 381.431 344.579 377.054 346.81C372.078 349.345 365.564 349.345 352.536 349.345H323.937Z"
          />
        </mask>
      </defs>
      <rect x="0" y="0" width="512" height="512" fill={`url(#${gradientId})`} mask={`url(#${maskId})`} />
    </svg>
  );
};
