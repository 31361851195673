import React from 'react';
import { useSession } from '../../hooks';
import { Button } from '../ui/button';

interface FacebookLoginButtonProps extends React.ComponentProps<typeof Button> {
  text?: string;
  size?: 'xs' | 'sm' | 'lg' | 'xl' | 'icon';
  redirectUrl?: string;
}

export const FacebookLoginButton = ({
  text = 'Continue with Facebook',
  size,
  redirectUrl = '/dashboard?authSuccess=true',
  ...props
}: FacebookLoginButtonProps) => {
  const { isLoggedIn, signIn, logOut, isLoading } = useSession();

  const handleClick = React.useCallback(() => {
    if (isLoggedIn) {
      logOut();
    } else {
      const encodedRedirect = encodeURIComponent(redirectUrl);
      signIn('facebook', {
        callbackUrl: `/login/success?redirect=${encodedRedirect}`
      });
    }
  }, [isLoggedIn, logOut, signIn, redirectUrl]);

  if (isLoading) {
    return null;
  }

  return (
    <Button
      {...props}
      size={size}
      onClick={handleClick}
      className="opacity-100 relative border border-black/20 dark:border-0 hover:opacity-90 bg-[#425998] hover:bg-[#425998] text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        aria-hidden="true"
        role="presentation"
        focusable="false"
        className="block h-5 w-5 absolute left-4 ring-2 ring-white rounded-sm bg-white"
      >
        <path fill="#1877F2" d="M32 0v32H0V0z"></path>
        <path
          fill="#FFF"
          d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.63h4.06V32h5V20.62h3.73l.7-4.62z"
        ></path>
      </svg>
      {text}
    </Button>
  );
};
