'use client';

import * as React from 'react';
import { Label } from '@components/ui/label';
import { cn } from '@/lib/utils';

type InputStatus = 'default' | 'warning' | 'error' | 'success';

interface InputWithLabelProps extends React.ComponentPropsWithoutRef<'input'> {
  label?: string;
  status?: InputStatus;
}

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
  ({ className, label, status = 'default', type, ...props }, ref) => {
    return (
      <div
        className={cn(
          'relative flex h-14 w-full flex-col items-start justify-center rounded-md border border-foreground/10 bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-within:outline-none focus-within:ring-1 focus-within:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          status === 'warning' && 'border-yellow-500/50 text-yellow-500 placeholder:text-yellow-500/80',
          status === 'error' && 'border-destructive text-destructive placeholder:text-destructive/80',
          status === 'success' && 'border-green-500/50 text-green-500 placeholder:text-green-500/80',
          className
        )}
      >
        <div className="flex w-full flex-col gap-0">
          {label && (
            <Label
              className={cn(
                'text-xs text-muted-foreground',
                status === 'warning' && 'text-yellow-500/80',
                status === 'error' && 'text-destructive/80',
                status === 'success' && 'text-green-500/80'
              )}
            >
              {label}
            </Label>
          )}
          <input
            type={type}
            className="w-full bg-transparent p-0 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
            ref={ref}
            {...props}
          />
        </div>
      </div>
    );
  }
);
InputWithLabel.displayName = 'InputWithLabel';

export { InputWithLabel };
