import React from 'react';
import { useSession } from '../../hooks';
import { Button, type ButtonProps } from '@components/ui/button';

interface GoogleLoginButtonProps extends Omit<ButtonProps, 'size'> {
  text?: string;
  size?: 'default' | 'xs' | 'sm' | 'lg' | 'xl' | 'icon';
  redirectUrl?: string;
}

export const GoogleLoginButton = ({
  text = 'Continue with Google',
  size = 'default',
  redirectUrl = '/dashboard?authSuccess=true',
  ...props
}: GoogleLoginButtonProps) => {
  const { isLoggedIn, signIn, logOut, isLoading } = useSession();

  const handleClick = React.useCallback(() => {
    if (isLoggedIn) {
      logOut();
    } else {
      const encodedRedirect = encodeURIComponent(redirectUrl);
      signIn('google', {
        callbackUrl: `/login/success?redirect=${encodedRedirect}`
      });
    }
  }, [isLoggedIn, logOut, signIn, redirectUrl]);

  if (isLoading) {
    return null;
  }

  return (
    <Button
      {...props}
      size={size}
      onClick={handleClick}
      className="opacity-100 relative border border-black/20 dark:border-0 bg-[#5385F3] hover:bg-[#5385F3] text-white hover:opacity-90"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        aria-hidden="true"
        role="presentation"
        focusable="false"
        className="block h-5 w-5 absolute left-4 ring-2 ring-white rounded-sm bg-white"
      >
        <path fill="#4285f4" d="M24.12 25c2.82-2.63 4.07-7 3.32-11.19H16.25v4.63h6.37A5.26 5.26 0 0 1 20.25 22z"></path>
        <path fill="#34a853" d="M5.62 21.31A12 12 0 0 0 24.12 25l-3.87-3a7.16 7.16 0 0 1-10.69-3.75z"></path>
        <path fill="#fbbc02" d="M9.56 18.25c-.5-1.56-.5-3 0-4.56l-3.94-3.07a12.08 12.08 0 0 0 0 10.7z"></path>
        <path
          fill="#ea4335"
          d="M9.56 13.69c1.38-4.32 7.25-6.82 11.19-3.13l3.44-3.37a11.8 11.8 0 0 0-18.57 3.43l3.94 3.07z"
        ></path>
      </svg>
      {text}
    </Button>
  );
};
