import * as React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@components/ui/card';
import { PicStudioGlyph } from '@components/PicStudioLogo/PicStudioGlyph';
import Link from 'next/link';
import { Alert, AlertDescription } from '@components/ui/alert';
import { GoogleLoginButton } from '@components/Button/GoogleLoginButton';
import { FacebookLoginButton } from '@components/Button/FacebookLoginButton';
import { EmailPasswordLogin } from '@components/EmailPasswordLoginForm';
import { useInAppBrowserCheck } from '@hooks/useInAppBrowserCheck';
import { cn } from '@lib/utils';
import { Separator } from '@components/ui/separator';

interface LoginCardProps {
  error?: string;
  promoCode?: string | null;
  callbackUrl: string;
  className?: string;
  showLogo?: boolean;
  title?: string;
  titleClassName?: string;
}

export function LoginCard({
  error,
  promoCode,
  callbackUrl,
  className = '',
  showLogo = true,
  title = 'Welcome to PicStudio.Ai',
  titleClassName = ''
}: LoginCardProps) {
  const { isInAppBrowser, isIOS } = useInAppBrowserCheck();
  const showGoogleButton = !(isIOS && isInAppBrowser);

  const getErrorMessage = (error: string) => {
    switch (error) {
      case 'OAuthSignin':
        return 'Error signing in with the provider. Please try again.';
      case 'OAuthCallback':
        return 'Error receiving data from the provider. Please try again.';
      case 'OAuthCreateAccount':
        return 'Error creating your account. Please try again.';
      case 'EmailCreateAccount':
        return 'Error creating your account. Please try again.';
      case 'Callback':
        return 'Error during authentication. Please try again.';
      case 'OAuthAccountNotLinked':
        return 'This email is already associated with another account. Please sign in with the original provider.';
      case 'EmailSignin':
        return 'Error sending the verification email. Please try again.';
      case 'CredentialsSignin':
        return 'Invalid credentials. Please check your email and password.';
      case 'SessionRequired':
        return 'Please sign in to access this page.';
      default:
        return 'An error occurred during authentication. Please try again.';
    }
  };

  return (
    <Card className={cn(className, 'w-[calc(100%-32px)] md:w-full max-w-md p-6 ')}>
      <CardHeader className="space-y-1">
        {showLogo && (
          <Link href="/">
            <PicStudioGlyph className="w-32 h-32 mx-auto" gradientColors={{ start: '#58C8FB', end: '#3871F0' }} />
          </Link>
        )}
        <CardTitle className={cn('text-2xl font-bold text-center', titleClassName)}>{title}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{getErrorMessage(error)}</AlertDescription>
          </Alert>
        )}
        {promoCode && (
          <div className="bg-gray-800 text-white rounded-md p-4 mb-2 w-full max-w-lg">
            <p>
              🎉 You will claim the coupon code: <span className="font-bold">{promoCode}</span> after logging in!
            </p>
          </div>
        )}
        {showGoogleButton && <GoogleLoginButton redirectUrl={callbackUrl} className="w-full" />}
        <FacebookLoginButton className="w-full" redirectUrl={callbackUrl} />
        <div className="relative flex items-center justify-center w-full gap-4 overflow-hidden py-4">
          <Separator className="flex-grow" />
          <span className="text-sm text-muted-foreground px-2">OR</span>
          <Separator className="flex-grow" />
        </div>
        <EmailPasswordLogin callbackUrl={callbackUrl} />
      </CardContent>
    </Card>
  );
}
