import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { useRouter } from 'next/router';
import { InputWithLabel } from './ui/input-with-label';

const EmailSchema = z.object({
  email: z.string().email({
    message: 'Please enter a valid email address.'
  })
});

type EmailData = z.infer<typeof EmailSchema>;

export interface EmailPasswordLoginProps {
  callbackUrl: string;
}

export function EmailPasswordLogin({ callbackUrl }: EmailPasswordLoginProps) {
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoginMode, setIsLoginMode] = React.useState(false);

  const emailForm = useForm({
    resolver: zodResolver(EmailSchema),
    defaultValues: {
      email: ''
    }
  });

  const onEmailSubmit = async (data: EmailData) => {
    setIsLoading(true);
    try {
      // Construct the query parameters
      const queryParams = new URLSearchParams({
        email: data.email,
        callbackUrl: encodeURIComponent(callbackUrl),
        mode: isLoginMode ? 'sign-in' : 'sign-up'
      });

      router.push(`/email/login?${queryParams.toString()}`);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      {isLoginMode ? <p className="text-sm text-center">Log in to your account</p> : null}
      <Form {...emailForm}>
        <form onSubmit={emailForm.handleSubmit(onEmailSubmit)} className="space-y-4">
          <FormField
            control={emailForm.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputWithLabel label="Email" placeholder="you@company.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-2">
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoginMode ? 'Continue to Login' : 'Continue to Sign Up'}
            </Button>
            <Button type="button" variant="ghost" className="w-full" onClick={() => setIsLoginMode(!isLoginMode)}>
              {isLoginMode ? "Don't have an account? Sign Up" : 'Already have an account? Log in'}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
